<template>
    <main>
        <page-header-compact>
            Configuración de historia clinica
        </page-header-compact>

        <div class="container-fluid px-4 mt-2">
            <div class="card card-icon mb-2">
                <div class="row no-gutters">
                    <div class="col-auto card-icon-aside bg-light">
                        <i class="fas fa-notes-medical"></i>
                    </div>
                    <div class="col">
                        <div class="card-body">
                            <div class="row">
                                <div class="form-group col-lg-6 col-md-4 col-sm-12">
                                    <label class="form-label">Profesional</label>
                                    <v-select-user v-model="pagination.params.id_user"></v-select-user>
                                </div>

                                <div class="form-group col-lg-6 col-md-4 col-sm-12">
                                    <label class="form-label">Sede</label>
                                    <VSelectSede v-model="pagination.params.id_sede"></VSelectSede>
                                </div>

                                <div class="form-group col-lg-6 col-md-4 col-sm-12">
                                    <label class="form-label">Fechas </label>
                                    <div >
                                        <date-picker class="w-100" required v-model="range_fechas" range
                                            valueType="format"></date-picker>
                                    </div>
                                </div>
                                <div class="form-group col-lg-4 col-md-4 col-sm-12">
                                    <label>Usuario</label>
                                    <input class="form-control form-control-sm" v-model="pagination.params.usuario"/>
                                </div>
                                <div class="mt-3 py-3">
                                    <button class=" btn btn-success btn-sm" @click="getHistoriaClinica();"><i
                                    class="fa fa-search fa-fw"></i>Buscar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card">
            <div class="card-body">
                <data-grid :data="pagination" @changePage="changePage($event)" @perPageChange="perPageChange($event)"
                    :searchBox="false">
                    <template #grid>
                        <grid-table>
                            <template #head>
                                <grid-cell-header class="bg-light" v-for="(h, i) in headers"
                                    :key="`h_${i}`">{{ h }}</grid-cell-header>
                            </template>
                            <template #body>
                                <grid-row v-for="(item, i) in pagination.data" :key="`d_${i}`">
                                    <grid-cell class="text-center small">
                                        {{ item.id }}
                                    </grid-cell>
                                    <grid-cell class="text-center small">
                                        ({{ item.usuario.documento }}) {{ item.usuario.nombre_1 }} {{ item.usuario.nombre_2 }}
                                        {{ item.usuario.apellido_1 }} {{ item.usuario.apellido_2 }}
                                    </grid-cell>
                                    <grid-cell class="text-center small">
                                        {{ item.tipo_historia.nombre }}
                                    </grid-cell>
                                    <grid-cell class="text-center small">
                                        {{ item.fecha | formatDate }}
                                    </grid-cell>
                                    <grid-cell class="text-center small">
                                        <span class="badge badge-success-soft text-sm"
                                            v-if="item.finalizada === 'S'">{{ item.finalizada }}</span>
                                        <span class="badge badge-danger-soft text-sm" v-else>{{ item.finalizada }}</span>
                                    </grid-cell>
                                    <grid-cell class="text-center small">
                                        {{ item.checkpoint }}
                                    </grid-cell>
                                    <grid-cell class="text-center small">
                                        {{ item.user.name }}
                                    </grid-cell>
                                    <grid-cell class="text-center small">
                                        {{ item.sede.NOMBRE_SEDE }}
                                    </grid-cell>
                                    <grid-cell class="text-center small">
                                        {{ item.ingreso }}
                                    </grid-cell>

                                    <grid-cell class="text-center">
                                        <div class="btn-group shadow-sm" role="group" >
                                            <button class="btn btn-outline-success btn-xs" type="button"
                                                @click="cambiar(item.id)">
                                                <i class="fas fa-edit fa-fw"></i> Cambiar estado
                                            </button>
                                            <button @click="imprimir(item.id);" class="btn btn-outline-primary  btn-xs" title="Imprimir Historia" >
                                                <i class="fa fa-print fa-fw"></i> Imprimir
                                            </button>
                                        </div>
                                    </grid-cell>
                                </grid-row>
                            </template>
                        </grid-table>
                    </template>
                </data-grid>
            </div>
        </div>
        </div>
    </main>
</template>
<script>
import PageHeaderCompact from "../../../components/layouts/content/page-header-compact";
import DataGrid from "../../../components/common/utilities/DataGrid/DataGrid";
import GridTable from "../../../components/common/utilities/DataGrid/GridTable";
import GridCellHeader from "../../../components/common/utilities/DataGrid/GridCellHeader";
import GridRow from "../../../components/common/utilities/DataGrid/GridRow";
import GridCell from "../../../components/common/utilities/DataGrid/GridCell";
import Toast from '../../../components/common/utilities/toast';
import vSelectUser from "../../../components/common/vSelectUser";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import VSelectSede from '../../../components/common/VSelectSede';
import historiaClinicaService from '../../../services/historiaClinicaService';

export default {
    components: {
        PageHeaderCompact, GridCell, GridRow, GridCellHeader, GridTable, DataGrid,
        vSelectUser, VSelectSede, DatePicker
    },
    data() {
        return {
            historiaClinica: [],
            headers: [
                'Hc No', 'Usuario', 'Tipo de Historia', 'Fecha', 'Finalizada', 'CheckPoint', 'Profesional', 'Sede', 'Ingreso', 'Opcion'
            ],
            range_fechas: '',
            pagination: {
                data: [],
                params: {
                    fecha: '',
                    id_user: '',
                    usuario: "",
                },
                current_page: 1,
                page: 1,
                per_page: 5,
                search: '',
                total: '',
            }
        }
    },

    methods: {
        async getHistoriaClinica() {

            try {

                this.LoaderSpinnerShow();

               
                this.pagination.params.start_date = this.range_fechas[0];
                this.pagination.params.end_date = this.range_fechas[1];

                const response = await historiaClinicaService.showByHC(this.pagination.search, this.pagination);
                this.historiaClinica = response.data;
                console.log(response.data);

                this.pagination.data = response.data.data;
                this.pagination.current_page = response.data.current_page;
                this.pagination.total = response.data.total;
                this.LoaderSpinnerHide();
            } catch (error) {
                console.log(error);
                this.LoaderSpinnerHide();
                Toast.fire({
                    icon: "error",
                    title: "Ha ocurrido un error al procesar la solicitud",
                });
            }

        },
        imprimir(id){
            window.open(historiaClinicaService.print(id));
        },
        async cambiar(id) {
            try {
                this.LoaderSpinnerShow();
                await historiaClinicaService.cambiarEstado(id);
                this.LoaderSpinnerHide();
                Toast.fire({
                    icon: 'success',
                    title: 'Estado cambiado exitosamente'
                });
                this.getHistoriaClinica();
            } catch (e) {
                console.error(e);
                this.LoaderSpinnerHide();
                Toast.fire({
                    icon: 'error',
                    title: 'Ocurrio un error al procesar la solicitud'
                });
            }
        },
       
        changePage(page) {
            this.pagination.page = page;
            this.getHistoriaClinica();
        },
        perPageChange(per_page) {
            this.pagination.per_page = per_page;
            this.pagination.page = 1;
            this.getHistoriaClinica();
        },
        search(query) {
            this.pagination.search = query;
            this.pagination.page = 1;
            this.getHistoriaClinica();
        }
    },

}
</script>
