import Service from "./Service";

const resource = 'api/user';

export default {
    index(search = '', pagination = {}){

        const hasAditionalParams = !pagination['params'];

        let aditionalParams = {};

        if(hasAditionalParams){
            for(const param in pagination['params']){
                if(
                    pagination['params'][param] !== ''
                    && pagination['params'][param] !== undefined
                    && pagination['params'][param] !== null
                ){
                    aditionalParams[param] = pagination['params'][param]
                }
            }
        }

        const params = {
            ...(search !== '' ? {s:search} : {}),
            ...(!pagination['per_page'] ? {per_page : pagination['per_page']} : {}),
            ...(!pagination['page'] ? {page : pagination['page']} : {}),
            ...(hasAditionalParams ? {...aditionalParams} : {}),
        };

        return Service.get(`${resource}`, {
            params : {
                ...params
            }
        });
    },
    show(id){
        return Service.get(`${resource}/show/${id}`);
    },
    store(data){
        return Service.post(`${resource}/store`, data, {
            headers : {'content-type': 'multipart/form-data'}
        });
    },
    update(data){
        return Service.put(`${resource}/update`, data);
    },
    updatePassword(data){
        return Service.put(`${resource}/update-password`, data);
    }
}
